var province_name_by_code={
   "NL" : "Newfoundland and Labrador",
   "PE" : "Prince Edward Island",
   "NS"	: "Nova Scotia",
   "NB" : "New Brunswick",
   "QC" : "Quebec",
   "ON" : "Ontario",
   "MB" : "Manitoba",
   "SK" : "Saskatchewan",
   "AB" : "Alberta",
   "BC" : "British Columbia",
   "YT" : "Yukon",
   "NT" : "Northwest Territories",
   "NU" : "Nunavut"
}