var currentDivName;
var mappingURL = "/raiseup/rupRoute/route";
var siteURL = "https://www.riseupcanada.org"
var aboutUs = "data/aboutus.json";
var aboutPartners = "data/aboutpartners.json";
var prayerPoints = "data/prayerpoints.json";
var prayerBenefits = "data/prayerbenefits.json";
var contactUs = "data/contactus.json";
var whyPray = "data/whypray.json";
var howToPray = "data/howtopray.json";
var breakingNews = "data/breakingnews.json";
var resourcesJSON = "data/resources.json";
var eventsJSON = "data/events.json";
var leadersJSON = "data/leader.json";
var socialprayersJSON = "data/prayers.json";
var provinceleadersJSON = "data/ProvinceLeaders.json";
var provincenewsJSON = "data/ProvinceNews.json";
var testimonialJSON = "data/testimonial.json";
var PP_ILLNESS = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/prayer_points/prayer_points_illness.pdf';
var AB_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/alberta.pdf';
var MB_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/manitoba.pdf';
var SK_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/saskatchewan.pdf';
var QC_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/quebec.pdf';
var NB_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/newbrunswick.pdf';
var NL_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/newfoundlandandlabrador.pdf';
var ON_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/ontario.pdf';
var NS_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/novaScotia.pdf';
var BC_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/britishcolombia.pdf';
var YT_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/yukon.pdf';
var PE_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/princeedwardisland.pdf';
var NU_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/nunavut.pdf';
var NT_ABOUT = 'https://www.riseupcanada.org/site/binaries/content/assets/ruc/about_province/northwestterritories.pdf';


var isLoggedIn = false;

//ajax call to retrieve data from data by call controller class in mentioned as url
function postData4Activity(url, data, successcallback, errorcallback) {
    rucLoading(true);
    $.ajax({
        url: url,
        type: "POST",
        data: data,
        cache: false,
        dataType: "json",
        contentType: 'application/json',
        processData: false,
        timeout: (60 * 10000),
        success: function(data) {
            if (data.error != undefined) {
                alert(data.error);
            } else {
                successcallback(data);
            }
        },
        error: function(objAJAXRequest, strError) {
            errorcallback(data);
            rucLoading(false);
        }
    });
    rucLoading(false);
}

function loadPage4Activity(divdom, page, callback, errorcallback) {
    rucLoading(true);
    $.get('pages/' + page + '.html', function(htmlContent, status, xhr) {
        if (xhr != null && xhr.status >= 200 && xhr.status <= 399) {
            $(divdom).html(htmlContent);
            if (typeof(LikeBtn) != "undefined") {
                LikeBtn.init();
            }
            if (callback != null) {
                callback();
            }
        } else {
            globalError('#' + xhr.status + ' page not found.');
            if (errorcallback != null) {
                errorcallback();
            }
        }
    });
    rucLoading(false);
}

function loadPage4ActivityST(divdom, page, callback, errorcallback, divname) {
    rucLoading(true);
    $.get('pages/' + page + '.html', function(htmlContent, status, xhr) {
        if (xhr != null && xhr.status >= 200 && xhr.status <= 399) {
            $(divdom).html(htmlContent);
            if (typeof(LikeBtn) != "undefined") {
                LikeBtn.init();
            }
            if (callback != null) {
                callback();
            }
            setTimeout(function() { loadTop(divname); }, 1000);
        } else {
            globalError('#' + xhr.status + ' page not found.');
            if (errorcallback != null) {
                errorcallback();
            }
        }
    });
    rucLoading(false);
}

function loadJSON(provinceCode, activity, callback) {
    rucLoading(true);
    $.getJSON('data/' + provinceCode.toLowerCase() + '/' + activity.toLowerCase() + '.json', function(data, status, xhr) {
        if (xhr != null && xhr.status >= 200 && xhr.status <= 399) {
            if (callback != null) {
                callback($.parseJSON(JSON.stringify(data)));
            }
        } else {
            globalError('#' + xhr.status + ' page not found.');
        }
    });
    rucLoading(false);
}

function loadRestJSON(activity, callback) {
    rucLoading(true);
    $.getJSON(activity, function(data, status, xhr) {
        if (xhr != null && xhr.status >= 200 && xhr.status <= 399) {
            if (callback != null) {
                callback($.parseJSON(JSON.stringify(data)));
            }
        } else {
            globalError('#' + xhr.status + ' page not found.');
        }
    });
    rucLoading(false);
}

function loadMenu(menuname) {
    rucLoading(true);
    if (menuname == "home") {
        location.reload();
    } else {
        $('#home-ruc-banner,#trending-wall,#prayer-leader,#resources-events-testimonial').hide();
        loadPage4Activity('#post-menu', "post-menu", null, null);
        loadPage4Activity('#container', menuname, null, null);
        $('#post-menu,#container').show();
        if (typeof(LikeBtn) != "undefined") {
            LikeBtn.init();
        }
    }
    rucLoading(false);
    $('html, body').animate({ scrollTop: 0 }, 'fast');
}

function loadMenuScrollTop(menuname, divname) {
    rucLoading(true);
    if (menuname == "home") {
        location.reload();
    } else {
        $('#home-ruc-banner,#trending-wall,#prayer-leader,#resources-events-testimonial').hide();
        loadPage4Activity('#post-menu', "post-menu", null, null);
        loadPage4ActivityST('#container', menuname, null, null, divname);
        $('#post-menu,#container').show();
        if (typeof(LikeBtn) != "undefined") {
            LikeBtn.init();
        }
    }
    rucLoading(false);
}

function removePTag(data) {
    return data.substring(3, data.length - 4);
}

function loadTop(divname) {
    divname = divname + '_content';
    $('html,body').animate({ scrollTop: ($('#' + divname).offset().top) }, 'slow');
}


function rucLoading(isShow) {
    if (isShow) { $("#rucloader").show(); } else { $("#rucloader").hide(); }
}

function successMessage(message) {
    Materialize.toast(message, 4000)
}

function errorMessage(message) {
    Materialize.toast('<p style="color:red">' + message + '</p>', 4000);
}

//loading html file  up on clicking on activities list
function loadContainerContents(filepath) {
    currentDivName = filepath;
}

function loadPP(link) {
    window.open(link, "_blank");
}

function loadAP() {
    window.open(eval(province_code.toUpperCase() + "_ABOUT"), "_blank");
}

function generateLikeButton(id) {
    return '<span class="likebtn-wrapper" data-theme="custom" data-btn_size="24" data-icon_l_url="https://www.riseupcanada.org/test/images/amen-btn.jpg" data-icon_l_url_v="https://www.riseupcanada.org/test/images/amen-btn-active.jpg" data-icon_size="5" data-i18n_like="Amen" data-white_label="true" data-identifier="' + id + '" data-dislike_enabled="false" data-icon_like_show="false" data-icon_dislike_show="false" data-voting_cancelable="false" data-counter_type="single_number" data-popup_disabled="true" data-share_enabled="false" data-tooltip_enabled="false" data-site_id="57c90a139b1d1b1c0689f324"></span><script>(function(d,e,s){if(d.getElementById("likebtn_wjs"))return;a=d.createElement(e);m=d.getElementsByTagName(e)[0];a.async=1;a.id="likebtn_wjs";a.src=s;m.parentNode.insertBefore(a, m)})(document,"script","//w.likebtn.com/js/w/widget.js");</script>';
}

function storeSession(emailid, name, islogin) {
    var userinfo = {};
    userinfo.emailid = emailid;
    userinfo.name = name;
    userinfo.islogin = islogin;
    if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage. 
        sessionStorage.setItem('userinfo', JSON.stringify(userinfo));
    } else {
        console.log("Sorry! No Web Storage support..");
    }
}

function removeSession() {
    sessionStorage.removeItem('userinfo');
}

function getSession() {
    return sessionStorage.getItem('userinfo');
}